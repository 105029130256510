<template>
  <div class="container">
    <div class="row studentAssignmentReportHeader">
      <div class="col s12 m10">
        <div class="assignmentReportHeader">
          <strong style="color: Black">Effort Analysis</strong>
          <strong><a style="color: #000" @click="Goback()"> / Assignment Report</a></strong>
          <strong
            ><a style="color: #642c90"> / {{ this.UserName }}</a></strong
          >
        </div>
      </div>
      <div class="col s12 m2 right-align">
        <a class="AffBackBtn btn" @click="Goback()"
          ><span class="Tiny material-icons" style="position: relative; top: 7px; margin-right: 2px"
            >arrow_back</span
          >Go Back</a
        >
      </div>
    </div>
    <div class="divider linediv hide-on-small-only" style="width: 100%"></div>
    <div class="card-panel Tcard">
      <div class="row">
        <div class="col l3 m6 s12">
          <label for="date" class="dropdownLabel">Date</label>
          <select
            name="date"
            id="date"
            class="browser-default selectDropdown"
            v-model="dateRangeType"
            @change="showCalendar()"
          >
            <option value="" disabled selected>--Select--</option>
            <option v-for="(period, index) in Timeinterval" :key="period" :value="index + 1">
              {{ period }}
            </option>
          </select>
        </div>
        <div class="col l3 m6 s12" v-if="isDateRangeOpen">
          <label for="startDate" class="dropdownLabel">Start Date</label>
          <input
            type="date"
            id="startDate"
            name="start date"
            max="2999-12-31"
            min="1900-01-01"
            class="browser-default datePicker"
            style="display: block"
            v-model="startDate"
            @change="showCalendar()"
          />
          <div v-show="isStartDateSelected" style="color: red">
            {{ this.DateValidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12" v-if="isDateRangeOpen">
          <label for="endDate" class="dropdownLabel">End Date</label>
          <input
            type="date"
            id="enddate"
            name="end Date"
            max="2999-12-31"
            min="1900-01-01"
            class="browser-default datePicker"
            style="display: block"
            v-model="endDate"
            @change="showCalendar()"
          />
          <div v-show="isEndDateSelected" style="color: red">
            {{ this.DateValidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12">
          <a class="AffsubmitBtn btn" @click="getStudentAssignmentReports()">Submit</a>
        </div>
      </div>
    </div>
    <div class="row tabelScroll" style="padding-bottom: 30px">
      <table class="striped" style="width: 100%">
        <thead>
          <tr>
            <th>Date</th>
            <!-- <th>Course</th>
          <th>Batch</th> -->
            <th>Description</th>
            <th>Type</th>
            <!-- <th>ExamId</th>
            <th>PdfId</th> -->
            <th>Questions</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody class="card-panel">
          <tr v-for="(report, index) in AssignmentTest" :key="index">
            <td>
              {{ formatDateByAlpha(report.CreatedOn ? report.CreatedOn : "-") }}
              <br />
              {{ formatTime(report.CreatedOn) }}
            </td>
            <td>{{ report.Description ? report.Description : "-" }}</td>
            <td>{{ report.AssignmentType ? report.AssignmentType : "-" }}</td>
            <!-- <td>{{ report.ExamId ? report.ExamId : "-" }}</td>
            <td>{{ report.PdfAssignmentId ? report.PdfAssignmentId : "-" }}</td> -->
            <td>{{ report.TotalQuestions ? report.TotalQuestions : 0 }}</td>
            <td>{{ report.Status ? report.Status : "-" }}</td>
            <td>
              <button
                class="viewReport"
                v-if="report.PdfAssignmentId"
                @click="viewPDF(report.PdfAssignmentId)"
              >
                View File
              </button>
              <button
                class="viewReport"
                v-if="report.ExamId && report.TotalQuestions"
                @click="viewQuestions(report.ExamId)"
              >
                View Questions
              </button>
              <button
                class="viewReport"
                v-if="report.Status === 'Completed' && report.FileUrl === ''"
                @click="viewReport(report.CreatedOn, report.Description, report.ExamSessionId)"
              >
                View Report
              </button>
              <!-- <a @click="viewReport(report)" class="viewReport">View Report</a> -->
            </td>
            <!-- <td><a class="viewReport">View Report</a></td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div :show="viewPdf">
      <Modal :show="viewPdf" @close="viewPdf = false" width="80%">
        <template #body>
          <iframe
            style="width: 100%; height: 600px"
            :src="'data:application/pdf;base64,' + this.getAssignmentUrl"
          ></iframe>
        </template>
      </Modal>
    </div>

    <div :show="viewQuestion">
      <Modal :show="viewQuestion" @close="viewQuestion = false" width="80%" :showCloseBtn="true">
        <template #body>
          <div
            class="diagram-img"
            v-for="(viewQuestionReport, index) in viewExamSessionReportData"
            :key="index"
          >
            <div class="concept-image">
              <p>
                <b>Question : {{ index + 1 }}</b>
              </p>
              <img :src="viewQuestionReport.QuestionDiagramURL" style="width: 100%" />
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>
<script>
import moment from "moment";
// import Vue from "vue";
import MobileAPI from "../Mobileapi";
import Modal from "../components/Modal.vue";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
//   }
//   return value;
// });
// Vue.filter("formatDateByAlpha", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("MMM Do YY");
//   }
//   return value;
// });
// Vue.filter("formatTime", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format(" LT");
//   }
//   return value;
// });
export default {
  data() {
    return {
      AssignmentTest: [],
      UserName: "",
      isDateRangeOpen: this.$route.params.IsDateRangeOpen,
      isStartDateSelected: false,
      showConceptModal: false,
      isEndDateSelected: false,
      getAssignmentUrl: "",
      PdfAssignmentId: null,
      viewPdf: false,
      viewQuestion: false,
      ExamId: null,
      disabled: false,
      startDate: "",
      endDate: "",
      date: null,
      CourseId: null,
      UserId: null,
      LocalData: [],
      DateValidationMessage: "",
      LocalCourseId: null,
      LocalUserId: null,
      dateRangeType: 1,
      Timeinterval: ["Today", "Yesterday", "Last Week", "Custom"],
      // getAssignmentTest: [],
      viewExamSessionReportData: [],
    };
  },
  activated() {
    const studentAssignmentReportParams = localStorage.getItem("StudentAssignmentReportParams");
    if (studentAssignmentReportParams.CourseId && studentAssignmentReportParams.UserId) {
      const localData = {
        CourseId: studentAssignmentReportParams.CourseId,
        UserId: studentAssignmentReportParams.UserId,
        UserName: studentAssignmentReportParams.UserName,
        StartDate: studentAssignmentReportParams.StartDate,
        EndDate: studentAssignmentReportParams.EndDate,
        IsDateRangeOpen:
          String(studentAssignmentReportParams.IsDateRangeOpen).toLowerCase() === "true",
        DateRangeType: studentAssignmentReportParams.DateRangeType,
      };
      localStorage.setItem("localDataNew", JSON.stringify(localData));
    }
    localStorage.removeItem("StudentAssignmentReportParams");

    const fetchLocal = JSON.parse(localStorage.getItem("localDataNew"));
    this.CourseId = fetchLocal.CourseId;
    this.UserId = fetchLocal.UserId;
    this.UserName = fetchLocal.UserName;
    this.startDate = fetchLocal.StartDate;
    this.endDate = fetchLocal.EndDate;
    this.isDateRangeOpen = fetchLocal.IsDateRangeOpen;
    this.dateRangeType = fetchLocal.DateRangeType;

    this.$store.dispatch("showLoader", true);
    MobileAPI.getAssignmentTestByDate(
      this.UserId,
      this.CourseId,
      this.startDate,
      this.endDate,
      (response) => {
        this.AssignmentTest = response.data;
      },
    );
    this.$store.dispatch("showLoader", false);
  },
  components: {
    Modal,
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
      }
      return value;
    },
    formatDateByAlpha(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("MMM Do YY");
      }
      return value;
    },
    formatTime(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format(" LT");
      }
      return value;
    },
    viewReport(createdOn, description, examSessionId) {
      this.$router.push({
        name: "AssignmentReportNew",
        params: {
          CreatedOn: createdOn,
          Description: description,
          // assignmentData,
          ExamSessionId: examSessionId,
        },
      });
    },
    showCalendar() {
      if (this.dateRangeType === 4) {
        this.isDateRangeOpen = true;
        this.isStartDateSelected = false;
        this.isEndDateSelected = false;
      } else {
        this.isDateRangeOpen = false;
      }
    },
    viewQuestions(question) {
      this.viewPdf = false;
      this.viewQuestion = true;
      this.viewExamSessionReportData = [];
      this.getAssignmentUrl = "";
      if (question) {
        this.ExamId = question;
        this.showConceptModal = true;
      } else {
        window.M.toast({
          html: "No file To view. Please try viewing other reports.",
        });
      }
      this.$store.dispatch("showLoader", true);
      MobileAPI.getAutoassignmentQuestionlist(
        {
          ExamId: this.ExamId,
        },
        (resp) => {
          if (resp.responseCode === 200) {
            this.viewExamSessionReportData = resp.data;
          }
        },
      );
      this.$store.dispatch("showLoader", false);
    },
    viewPDF(report) {
      this.viewPdf = true;
      this.viewQuestion = false;
      this.getAssignmentUrl = "";
      this.viewExamSessionReportData = [];
      if (report) {
        this.PdfAssignmentId = report;
        this.showConceptModal = true;
      } else {
        window.M.toast({
          html: "No file To view. Please try viewing other reports.",
        });
      }
      this.$store.dispatch("showLoader", true);

      MobileAPI.getAssignmentUrl(this.PdfAssignmentId, (response) => {
        this.getAssignmentUrl = response.data.FileContent;
      });
      this.$store.dispatch("showLoader", false);
    },
    Goback() {
      this.$router.push({
        name: "AssignmentReport",
        params: {
          StudentAssignmentReportCourseId: this.CourseId,
        },
      });
      this.AssignmentTest = [];
      // this.dateRangeType = null;
    },
    getStudentAssignmentReports() {
      localStorage.removeItem("localDataNew");
      let beginDate = moment();
      let endingDate = moment();
      if (this.dateRangeType === -1) {
        return;
      }
      if (this.dateRangeType === 1) {
        beginDate = beginDate.format("YYYY-MM-DD");
        endingDate = endingDate.format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 2) {
        beginDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        endingDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 3) {
        beginDate = moment().subtract(1, "weeks").startOf("week");
        beginDate = beginDate.format("YYYY-MM-DD");
        endingDate = moment().format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 4) {
        beginDate = this.startDate;
        endingDate = this.endDate;
        if (beginDate === "") {
          this.DateValidationMessage = "Please Select start date";
          this.isStartDateSelected = true;
          return;
        }
        if (endingDate === "") {
          this.DateValidationMessage = "Please Select end date";
          this.isEndDateSelected = true;
          this.isStartDateSelected = false;
          return;
        }
        if (beginDate !== "" && endingDate !== "") {
          beginDate = moment(this.startDate, "YYYY-MM-DD");
          endingDate = moment(this.endDate, "YYYY-MM-DD");
          const differenceOfDays = endingDate.diff(beginDate, "days");
          if (differenceOfDays < 0) {
            window.M.toast({
              html: "End Date can not be less than Start Date",
            });
            return;
          }
          if (differenceOfDays > 30) {
            window.M.toast({
              html: "Select date range between 30 days",
            });
            return;
          }
        }
      }
      if (this.startDate && this.endDate) {
        const localData = {
          CourseId: this.CourseId,
          UserId: this.UserId,
          UserName: this.UserName,
          StartDate: this.startDate,
          EndDate: this.endDate,
          IsDateRangeOpen: this.isDateRangeOpen,
          DateRangeType: this.dateRangeType,
        };
        localStorage.setItem("localDataNew", JSON.stringify(localData));
      }

      const fetchLocal = JSON.parse(localStorage.getItem("localDataNew"));
      this.CourseId = fetchLocal.CourseId;
      this.UserId = fetchLocal.UserId;
      this.UserName = fetchLocal.UserName;
      this.startDate = fetchLocal.StartDate;
      this.endDate = fetchLocal.EndDate;
      this.isDateRangeOpen = fetchLocal.IsDateRangeOpen;
      this.dateRangeType = fetchLocal.DateRangeType;
      const data = {
        // CourseId: 1,
        // BatchId: 413,
        // FromDate: "2022-06-01",
        // ToDate: "2022-06-29",
        userId: this.UserId,
        courseId: this.CourseId,
        fromDate: this.startDate,
        toDate: this.endDate,
      };
      MobileAPI.getAssignmentTestByDate(
        data.userId,
        data.courseId,
        data.fromDate,
        data.toDate,
        (response) => {
          this.AssignmentTest = response.data;
          if (response.responseCode === 201) {
            window.M.toast({
              html: "No records found for your current selection. Please try a different selection",
            });
            this.AssignmentTest = [];
          } else if (this.AssignmentTest.length === 0) {
            window.M.toast({
              html: "No records found for your current selection. Please try a different selection",
            });
          }
        },
      );
    },
  },
};
</script>

<style scoped>
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #f59e00;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #f59e00;
}
.assignmentReportHeader {
  font-size: 25px;
  color: #642c90;
}

.linediv {
  /* margin-top: 18.5px; */
  height: 0px;
  left: 132px;
  border: 1px solid #e0e0e0;
}
.Tcard {
  border-radius: 5px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.dropdownLabel {
  font-size: 19px;
  color: #000;
  font-weight: 600;
}
.selectDropdown {
  margin-top: 8px;
  border-radius: 5px;
  height: 36px;
}
.datePicker {
  margin-top: 8px;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1.5px solid #e3e3e3;
}
.studentAssignmentReportHeader {
  padding: 20px 0 0 0;
}
.AffBackBtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  width: 130px;
  height: 38px;
}
.AffsubmitBtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  margin-left: 41px;
  margin-top: 36px;
  width: 130px;
  height: 38px;
}
.viewReport {
  padding: 10px 25px;
  background-color: #6a2f85;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin: 5px;
  cursor: pointer;
}

thead {
  background-color: #6a2f85;
}
th {
  color: #fff;
  border: none;
  border-radius: 0;
}
table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
}
tbody {
  border-radius: 0 0 5px 5px;
}
@media screen and (max-width: 600px) {
  .AffsubmitBtn {
    margin-left: 0;
  }
  .tabelScroll {
    overflow: scroll;
    padding: 10px;
  }
  .viewReport {
    font-size: 10px;
  }
}
</style>
